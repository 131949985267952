import { useSelector, useDispatch } from 'react-redux';
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import SearchArea from "./area/search_area";

function FindStudyTitlePopup(props: any) {
  let myProps = {
    closePopup: () => { },
    listOpt: {},
    row_num: 0,
    key_str: "",
    par_info:null,
    callBackData: (data: any) => {
      let optObj = {
        info_arr: [],
        row_num: 0,
        key: "",
        par_info:null,
      };
    },
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>(null);
  const [pageData, setPageData] = useState({
    listOpt: {
      ...xColumnArr.list_opt,
      "s_subject_seq": "",
      "s_subject_sort": "",
      //"s_addon_title_img":"1",
      "s_addon_subject_title": "1",
      "s_addon_section": "1",
      "s_addon_study": "1",
      //"s_addon_use_stu":"1",
      "s_addon_use":"1",
      "s_addon_use_s_stu_seq":"",

      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      ...myProps.listOpt
    }
  });
  const [infoArr, setInfoArr] = useState([]);
  useEffect(() => {
    
  }, []);
  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData = pageData;
      if (listAggridRef.current) {
        inOptObj["sc"] = listAggridRef.current.getListSc();
      }
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/plan/study/study_title/list', pageData.listOpt,get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            response.data["data"]["info_arr"][i]["idx_num"] = i + 1;
          }
          setInfoArr(response.data["data"]["info_arr"]);
          listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const on_select_click=()=>{
    let selected_row=listAggridRef.current.getSelectedRows();
    if(selected_row.length==0){
      alert("선택이 없습니다.");
      return false;
    }
    
    myProps.callBackData({
      info_arr: selected_row,
      row_num: myProps.row_num,
      key: myProps.key_str,
      par_info:myProps.par_info,
    });
    myProps.closePopup();
  };

  const cellClickedListener = useCallback((params: any) => {
    var key = params.colDef.field;
    if (key == "a_user_name" || key == "a_user_id") {
      //openOnePopup("view",true);
    }
  }, []);

  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;

    if (key == "row_view_section") {
      let section_num = 0;
      let study_num = 0;
      if (params.data.section_arr) {
        section_num = params.data.section_arr.length;
      }
      if (params.data.study_arr) {
        study_num = params.data.study_arr.length;
      }
      render_str = section_num + "/" + study_num;
    }else if(key=="row_view_use"){
      if(params.data.use_info_arr&&params.data.use_info_arr.length>0){
        render_str=params.data.use_info_arr.length;
      }
    }

    return render_str;
  }, []);
  return (
    <div>
      <SearchArea
        listOpt={pageData.listOpt}
        list={list}
        on_select_click={on_select_click}
      ></SearchArea>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: true,
          is_add_checkbox: false,
          floatingFilter: true,
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
        }}
      ></ListAggrid>
    </div>
  );
};
export default FindStudyTitlePopup;