import { useState, useRef, useEffect, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios/index";
import DateFunc from '@/lib/lyg/date_func';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import getXcolumnJson from "./xcolumn/list";
import ListAggrid from '@/pcomponents/common/crud/list/aggrid/list';
import strFunc from '@/lib/lyg/string';
import SearchArea from "./area/search";
import ListFunc from "./func/list_func";
import { info } from 'console';

const StuSearchList = forwardRef((props: any, ref) => {
  const myProps = {
    list: (inData: any) => { },
    set_content_tab: (inData: any) => { },
    ...props
  };

  const xColumnArr = getXcolumnJson();
  const listAggridRef = useRef<any>();
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_grade: ["student"],
    "s_class_seq": "",
    "s_stu_grade": "",
    "s_stu_state": "now",
    s_seq: [],
    select_stu_seq: "",
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
    s_stu_lecture_seq: strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_addon_month_prescribe: "1",
    s_addon_month_prescribe_start_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-01"),
    s_addon_month_prescribe_end_date: DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', +1), "Y-m-t"),
    s_addon_month_prescribe_writer_seq: "",
  });
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [lectrue_select_arr, set_lectrue_select_arr] = useState<any>([]);

  useEffect(() => {
    get_lecture_select_arr();
  }, []);

  const get_lecture_select_arr = () => {
    let class_list_form = {
      "s_state": "ing",
      "order_id": "a_order_num",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', class_list_form, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_lectrue_select_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const list = (inOptObj: any) => {
    let tmpListOpt = {
      ...listOpt,
      ...inOptObj
    };
    tmpListOpt["sc"] = listAggridRef.current.getListSc();
    setListOpt(tmpListOpt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', tmpListOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          response.data["data"]["info_arr"] = ListFunc.get_list_info_arr({ info_arr: response.data["data"]["info_arr"] });
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          if (listAggridRef.current) {
            listAggridRef.current.setInfoArr(response.data["data"]["info_arr"]);
            //select_stu_seq
            if (!strFunc.is_empty(inOptObj["select_stu_seq"])) {
              setTimeout(() => {
                let listNodes = listAggridRef.current.getNodeRows();
                for (let i = 0; i < listNodes.length; i++) {
                  let node = listNodes[i];
                  if (inOptObj["select_stu_seq"] == node.data["a_seq"]) {
                    listNodes[i].setSelected(true);
                  } else {
                    listNodes[i].setSelected(false);
                  }
                }
              }, 100);
            }
          }
          onSelectionChanged({ 
            info_arr: response.data["data"]["info_arr"], 
            s_lecture_seq: tmpListOpt.s_stu_lecture_seq 
          });
        } else {

        }
      });
  };

  const cellRenderOfMonthPrescribe = (params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    let tot_span_style: any = {
      display: "inline-block",
      textAlign: "center",
      lineHeight: "26px",
      height: "26px",
      padding: "0px 8px",
      background: "gray",
      color: "#fff",
      borderRadius: "5px"
    };
    if (params.data.month_prescribe_arr == undefined) {
      return "";
    }
    if (params.data.month_prescribe_arr.length == 0) {
      return "";
    }
    if (key == "row_view_month_prescribe") {
      if (params.data.month_prescribe_arr.length > 0) {
        let row_mprescribe_arr = params.data.month_prescribe_arr;
        let row_mprescribe_arr_len = row_mprescribe_arr.length;
        let row_success_cnt = 0;
        for (let i = 0; i < row_mprescribe_arr_len; i++) {
          let row_mprescribe = row_mprescribe_arr[i];
          if (row_mprescribe["a_is_success"] == "1") {
            row_success_cnt++;
          }
        }

        if (row_mprescribe_arr_len > 0) {
          tot_span_style["background"] = "#db6c58";
          if (row_success_cnt > 0) {
            tot_span_style["background"] = "#eab358";
            if (row_mprescribe_arr_len == row_success_cnt) {
              tot_span_style["background"] = "#a2b854";
            }
          }
        }
        render_str = (
          <div style={tot_span_style}>
            {row_success_cnt + "/" + row_mprescribe_arr_len}
          </div>
        );
      }
    } else {
      let custom_prescribe_data: any = {
        "math": {
          arr: [],
          cnt: 0,
          success: 0,
        },
        "en": {
          arr: [],
          cnt: 0,
          success: 0,
        },
      };
      for (let i = 0; i < params.data.month_prescribe_arr.length; i++) {
        if (params.data.month_prescribe_arr[i]["a_subject_name"] == "수학") {
          custom_prescribe_data["math"]["arr"].push(params.data.month_prescribe_arr[i]);
          custom_prescribe_data["math"]["cnt"]++;
          if (params.data.month_prescribe_arr[i]["a_is_success"] == "1") {
            custom_prescribe_data["math"]["success"]++;
          }
        } else if (params.data.month_prescribe_arr[i]["a_subject_name"] == "영어") {
          custom_prescribe_data["en"]["arr"].push(params.data.month_prescribe_arr[i]);
          custom_prescribe_data["en"]["cnt"]++;
          if (params.data.month_prescribe_arr[i]["a_is_success"] == "1") {
            custom_prescribe_data["en"]["success"]++;
          }
        }
      }
      if (key == "row_view_month_prescribe_math") {
        if (custom_prescribe_data["math"]["cnt"] > 0) {
          tot_span_style["background"] = "#db6c58";
          if (custom_prescribe_data["math"]["success"] > 0) {
            tot_span_style["background"] = "#eab358";
            if (custom_prescribe_data["math"]["cnt"] == custom_prescribe_data["math"]["success"]) {
              tot_span_style["background"] = "#a2b854";
            }
          }
        }
        render_str = (
          <div style={tot_span_style}>
            {custom_prescribe_data["math"]["success"] + "/" + custom_prescribe_data["math"]["cnt"]}
          </div>
        );
        if (custom_prescribe_data["math"]["cnt"] == 0) {
          render_str = "";
        }
      } else if (key == "row_view_month_prescribe_en") {
        if (custom_prescribe_data["en"]["cnt"] > 0) {
          tot_span_style["background"] = "#db6c58";
          if (custom_prescribe_data["en"]["success"] > 0) {
            tot_span_style["background"] = "#eab358";
            if (custom_prescribe_data["en"]["cnt"] == custom_prescribe_data["en"]["success"]) {
              tot_span_style["background"] = "#a2b854";
            }
          }
        }
        render_str = (
          <div style={tot_span_style}>
            {custom_prescribe_data["en"]["success"] + "/" + custom_prescribe_data["en"]["cnt"]}
          </div>
        );
        if (custom_prescribe_data["en"]["cnt"] == 0) {
          render_str = "";
        }
      }
    }
    return render_str;
  };

  const setSelectFalseAll = () => {
    listAggridRef.current.setSelectFalseAll();
    onSelectionChanged({});
  };

  const cellClickedListener = (params: any) => {
    var key = params.colDef.field;
    if (key == "a_name") {

    }
  }

  const removeSelectStu = (stu_seq: any) => {
    let all_node_rows = listAggridRef.current.getNodeRows();
    for (let i = 0; i < all_node_rows.length; i++) {
      let tmp_seq = all_node_rows[i].data["a_seq"];
      if (tmp_seq == stu_seq) {
        all_node_rows[i].setSelected(false);
      }
    }
    setTimeout(() => {
      onSelectionChanged({});
    }, 100);
  };

  const set_height = (height: any) => {
    if (listAggridRef.current) {
      listAggridRef.current.setHeight(height);
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    list, setSelectFalseAll, removeSelectStu, set_height
  }));


  const cellRenderer = useCallback((params: any) => {
    var key = params.colDef.field;
    var render_str = params.value;
    if (key == "row_view_manage") {

    } else if (key == "row_view_class_name") {
      if (params.data.class_arr) {
        let class_name_arr = [];
        for (let i = 0; i < params.data.class_arr.length; i++) {
          class_name_arr.push(params.data.class_arr[i]["class_name"]);
        }
        render_str = class_name_arr.join(" / ");;
      }
    } else if (key == "row_view_month_prescribe"
      || key == "row_view_month_prescribe_math"
      || key == "row_view_month_prescribe_en") {
      render_str = cellRenderOfMonthPrescribe(params);
    } else if (key == "row_view_school_grade") {
      let row_grade_str = params.data.a_stu_grade;
      if (xColumnArr.select_arr.a_stu_grade) {
        for (let i = 0; i < xColumnArr.select_arr.a_stu_grade.length; i++) {
          if (xColumnArr.select_arr.a_stu_grade[i]["value"] == params.data.a_stu_grade) {
            row_grade_str = xColumnArr.select_arr.a_stu_grade[i]["text"];
          }
        }
      }
      let row_school_name = strFunc.cut_str(params.data.a_school_name, 2, "");
      render_str = row_school_name + " " + row_grade_str;
    }
    return render_str;
  }, []);

  const onSelectionChanged = (event: any) => {
    let opt_obj = {
      info_arr: infoArr,
      s_lecture_seq: listOpt.s_stu_lecture_seq,
      ...event
    };
    let selected_row = listAggridRef.current.getSelectedRows();
    let select_seq_arr = [];
    if (selected_row) {
      for (let i = 0; i < selected_row.length; i++) {
        select_seq_arr.push(selected_row[i]["a_seq"]);
      }
    }
    if (select_seq_arr.length == 0) {
      // selected_row = [...opt_obj.info_arr];
      // for (let i = 0; i < selected_row.length; i++) {
      //   select_seq_arr.push(selected_row[i]["a_seq"]);
      // }
    }

    myProps.list({
      "s_stu_user_seq": select_seq_arr,
      "s_stu_info_arr": selected_row,
      "s_lecture_seq": opt_obj.s_lecture_seq,
    });
  };

  return (
    <div>
      <SearchArea
        list={list}
        listOpt={listOpt}
        lectrue_select_arr={lectrue_select_arr}
        xColumnArr={xColumnArr}
        set_content_tab={myProps.set_content_tab}
      ></SearchArea>
      <ListAggrid
        ref={listAggridRef}
        infoArr={infoArr}
        xColumnArr={{ ...xColumnArr }}
        list={list}
        gridOpt={{
          fix_left_num: xColumnArr.list_opt_arr.fix_left_num,
          is_idx_num: false,
          is_add_checkbox: true,
          floatingFilter: false,
          rowMultiSelectWithClick: true,
          height: "60vh",
          onGridReady: () => {
            list({});
          },
          onCellClicked: cellClickedListener,
          cellRenderer: cellRenderer,
          onSelectionChanged: onSelectionChanged
        }}
      ></ListAggrid>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
    </div>
  );
});

export default StuSearchList;
