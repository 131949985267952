import strFunc from "@/lib/lyg/string";
import MonthPrescribeViewFunc from "@/pcomponents/common/content/edu/month_prescribe/view/view_func";

function MonthPrescribeStateTd(props: any) {
  let myProps = {
    info: {},
    open_stu_layer_popup: (inData: any) => { },
    ...props
  };
  let info = myProps.info;
  let month_prescribe_arr = [];
  if (info.month_prescribe_arr) {
    month_prescribe_arr = info.month_prescribe_arr;
  }

  let custom_prescribe_data: any = {
    "math": {
      arr: [],
      cnt: 0,
      success: 0,
    },
    "en": {
      arr: [],
      cnt: 0,
      success: 0,
    },
  };
  for (let i = 0; i < month_prescribe_arr.length; i++) {
    if (month_prescribe_arr[i]["a_subject_name"] == "수학") {
      custom_prescribe_data["math"]["arr"].push(month_prescribe_arr[i]);
      custom_prescribe_data["math"]["cnt"]++;
      if (month_prescribe_arr[i]["a_is_success"] == "1") {
        custom_prescribe_data["math"]["success"]++;
      }
    } else if (month_prescribe_arr[i]["a_subject_name"] == "영어") {
      custom_prescribe_data["en"]["arr"].push(month_prescribe_arr[i]);
      custom_prescribe_data["en"]["cnt"]++;
      if (month_prescribe_arr[i]["a_is_success"] == "1") {
        custom_prescribe_data["en"]["success"]++;
      }
    }
  }

  const get_month_subject_each_tag = (inData: any) => {
    let opt_obj = {
      subject_sort: "math",//math,en
      subject_name: "M",//M,E
      subject_data: {
        arr: [],
        cnt: 0,
        success: 0,
      },
      ...inData
    };
    let subject_sort = opt_obj["subject_sort"];
    let subject_name = opt_obj["subject_name"];
    let subject_data = opt_obj["subject_data"];

    let subject_span_style: any = {
      display: "inline-block",
      padding: "0px 3px",
      borderRadius: "3px",
      background: "#000",
      color: "#fff",
    };
    let title_span_style: any = {
      ...subject_span_style
    };
    let counsel_span_style: any = {};
    let is_success = false;
    let is_success_check = false;
    let is_request_counsel = false;

    if (subject_data["cnt"] > 0) {
      title_span_style["background"] = "#7f7f7f";
      for (let i = 0; i < subject_data["arr"].length; i++) {
        let row_prescribe = subject_data["arr"][i];
        if (row_prescribe["a_is_success_check"] == "1") {
          is_success_check = true;
        }
        if (row_prescribe.comment_arr && row_prescribe.comment_arr.length > 0) {
          title_span_style["background"] = "#ed3a3a";
          let comment_last_idx = row_prescribe.comment_arr.length - 1;
          if (row_prescribe.comment_arr[comment_last_idx]["a_qna_sort"] == "a") {
            title_span_style["background"] = "#ffbc00";
          }
        }
        if (row_prescribe["a_is_request_counsel"] == "1") {
          is_request_counsel = true;
          if (strFunc.is_empty(row_prescribe.a_request_counsel_seq) == false) {
            counsel_span_style["background"] = "#00ca49";
            counsel_span_style["color"] = "#fff";
            counsel_span_style["borderRadius"] = "5px";
            counsel_span_style["padding"] = "0px 2px";
          }
        }
      }
      if (subject_data["cnt"] == subject_data["success"]) {
        is_success = true;
        title_span_style["background"] = "#a4b75d";
      }
    }
    let render_tag_str: any = "";
    if (subject_data["cnt"] == 0) {
      return render_tag_str = "";
    }

    let month_prescribe_title_str: any = "";
    if (subject_data["cnt"] > 0) {
      subject_data["arr"][0].stu_info=info;
      month_prescribe_title_str = MonthPrescribeViewFunc.get_view_text_str({
        info: subject_data["arr"][0],
      });
    }

    render_tag_str = (
      <div style={{ display: "inline-block", position: "relative" }} title={month_prescribe_title_str}>
        <span style={title_span_style}>
          {subject_name}
          {subject_data["cnt"]}
        </span>
        {(is_success_check && is_success) &&
          <span>(확)</span>
        }
        {is_request_counsel &&
          <span style={counsel_span_style}>상</span>
        }
      </div>
    );
    return render_tag_str;
  };

  const get_month_subject_tag = () => {
    let math_render_tag = get_month_subject_each_tag({
      subject_sort: "math",
      subject_name: "M",
      subject_data: custom_prescribe_data["math"],
    });
    let en_render_tag = get_month_subject_each_tag({
      subject_sort: "en",
      subject_name: "E",
      subject_data: custom_prescribe_data["en"],
    });

    let month_subject_tag: any = (
      <div
        style={{ cursor: "pointer" }}
        onClick={(e: any) => {
          myProps.open_stu_layer_popup({
            stu_info: info,
            is_scroll: true,
          });
        }}>
        {math_render_tag}
        <span className="ml-1">
          {en_render_tag}
        </span>
      </div>
    );

    return month_subject_tag;
  };

  return (
    <td>
      {get_month_subject_tag()}
    </td>
  );
};
export default MonthPrescribeStateTd;