import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import Style from "./css/style.module.css";
import left_arrow from "@/img/ico/arrow/left_arrow.png";
import right_arrow from "@/img/ico/arrow/right_arrow.png";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';

const SearchArea=(props:any)=>{
  let myProps={
    list:(inData:any)=>{},
    listOpt:{
      s_select_date:"",
    },
    now_ymdhis:"",
    ...props
  };

  let now_date_json=DateFunc.get_date_json(new Date(myProps.now_ymdhis));

  //const [now_sec, set_now_sec] = useState(strFunc.timeToSeconds(now_date_json.h,now_date_json.i,now_date_json.s)); 
  let now_sec_json=strFunc.secondsToTimeJson(strFunc.timeToSeconds(now_date_json.h,now_date_json.i,now_date_json.s));
  let now_his=now_sec_json.h+":"+now_sec_json.i+":"+now_sec_json.s;

  let now_ymd=DateFunc.get_date_format(new Date(),"Y-m-d");
  let pre_date_obj=DateFunc.get_change_date(new Date(myProps.listOpt.s_select_date),'day',-1);
  let pre_date_json=DateFunc.get_date_json(pre_date_obj);
  let after_date_obj=DateFunc.get_change_date(new Date(myProps.listOpt.s_select_date),'day',+1);
  let after_date_json=DateFunc.get_date_json(after_date_obj);
  let select_date_json=DateFunc.get_date_json(new Date(myProps.listOpt.s_select_date));

  let select_date_str=select_date_json["m"]+"월 "+select_date_json["d"]+"일";
  if(myProps.listOpt.s_select_date==now_ymd){
    //select_date_str+="(오늘)";
  }
  let day_of_week_str_arr=["일","월","화","수","목","금","토"];
  if(day_of_week_str_arr[select_date_json.day]!=undefined){
    select_date_str+="("+day_of_week_str_arr[select_date_json.day]+")";
  }
  let pre_date_str=pre_date_json["m"]+"월 "+pre_date_json["d"]+"일";
  let after_date_str=after_date_json["m"]+"월 "+after_date_json["d"]+"일";

  return (
    <div className={Style.search_box}>
      <span className={Style.pre_date_span}
        onClick={()=>{myProps.list({"now_page":"1","s_select_date":DateFunc.get_date_format(pre_date_obj,"Y-m-d")});}} >{pre_date_str}</span>
      <span className={Style.select_date_span} 
        onClick={()=>{myProps.list({"now_page":"1","s_select_date":DateFunc.get_date_format(new Date(),"Y-m-d")});}}>
        {select_date_str}
      </span>
      <span className={Style.after_date_span}
        onClick={()=>{myProps.list({"now_page":"1","s_select_date":DateFunc.get_date_format(after_date_obj,"Y-m-d")});}} >
        {after_date_str}
      </span>

      <div className={Style.sub_time_span}>
        {now_his}
      </div>
    </div>
  );
};
export default SearchArea;