import Style from "../../css/style.module.css";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import DateFunc from '@/lib/lyg/date_func';
import SearchArea from "../search";
import SearchRoomArea from "../search_room";
import RoomListArea from "../room_list";

import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindStudentPopup from "../../popup/find_stu_popup";
import ViewDeskPopup from "../../popup/view_desk_popup";
import strFunc from "@/lib/lyg/string";
import { useSelector, useDispatch } from 'react-redux';

function RoomListWrap(props: any) {
  let myProps = {
    s_room_seq: "",
    is_show_search_room: true,
    is_show_paging: true,
    is_detal_popup: true,
    ...props
  };
  let user = useSelector((state: any) => state.user);
  const [listOpt, setListOpt] = useState({
    now_page: 1,
    num_per_page: 20,
    order_id: 'a_order_num, a_name',
    order_type: '',
    s_room_seq: myProps.s_room_seq,
    s_is_use: "1",
    s_addon_desk: "1",
    s_addon_use: "1",
    s_addon_lecture: "1",
    s_addon_now_time: "1",
    s_select_date: DateFunc.get_date_format(new Date(), "Y-m-d"),
  });
  let RoomListAreaRef = useRef();
  const [search_room_list, set_search_room_list] = useState([]);
  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [now_ymdhis, set_now_ymdhis] = useState(DateFunc.get_date_format(new Date(), "Y-m-d h:i:s"));
  const now_ymdhis_ref = useRef(now_ymdhis);

  const [popupData, setPopupData] = useState({
    room_info: null,
    desk_info: null,
    desku_info: null,
    now_ymdhis: "",
    now_lecture_data: null,
    isOpen: false,
    sort: "find_stu",//find_stu,view_desk
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    search_room_list_by_ajax();
    list({});
  }, []);

  useEffect(() => {
    const count_loop = setInterval(tick_now_ymdhis, 1000);
    return () => {
      clearInterval(count_loop);
    };
  }, []);

  const tick_now_ymdhis = () => {
    let tick_date_obj = DateFunc.get_change_date(new Date(now_ymdhis_ref.current), 'sec', +1);
    now_ymdhis_ref.current = DateFunc.get_date_format(tick_date_obj, "Y-m-d h:i:s");
    set_now_ymdhis(now_ymdhis_ref.current);
    if (parseInt(DateFunc.get_date_format(tick_date_obj, "s")) == 0) {
      list({});
    }
  };

  const search_room_list_by_ajax = () => {
    let search_room_form_json = {
      order_id: 'a_order_num, a_name',
      order_type: '',
      s_is_use: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', search_room_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] == "true") {
          set_search_room_list(response.data["data"]["info_arr"]);
        }
      });
  };

  const list = (inOptObj: any) => {
    let tmp_listOpt:any={
      ...listOpt,
      ...inOptObj
    };
    setListOpt(tmp_listOpt);
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', tmp_listOpt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          set_now_ymdhis(response.data["data"]["now_ymdhis"]);
          now_ymdhis_ref.current = response.data["data"]["now_ymdhis"];
        } else {

        }
      });
  };

  const open_find_stu_popup = (inData: any) => {
    let opt_obj: any = {
      "desk_info": null,
      "now_ymdhis": "",
      "now_lecture_data": null,
      ...inData,
    };

    setPopupData({
      ...popupData,
      "desk_info": opt_obj["desk_info"],
      "now_ymdhis": opt_obj["now_ymdhis"],
      "now_lecture_data": opt_obj["now_lecture_data"],
      "isOpen": true,
      "title": "학생찾기",
      "sort": "find_stu"
    });
  };

  const open_view_desk_popup = (inData: any) => {
    let opt_obj = {
      room_info: null,
      desk_info: null,
      desku_info: null,
      now_ymdhis: "",
      now_lecture_data: null,
      ...inData
    };
    setPopupData({
      ...popupData,
      "room_info": opt_obj["room_info"],
      "desk_info": opt_obj["desk_info"],
      "desku_info": opt_obj["desku_info"],
      "now_ymdhis": opt_obj["now_ymdhis"],
      "now_lecture_data": opt_obj["now_lecture_data"],
      "isOpen": true,
      "title": "책상보기",
      "sort": "view_desk"
    });
  };

  const callBackDataFindStu = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      "desk_info": null,
      "now_ymdhis": "",
      "select_time": {
        "start_h": 0,
        "start_m": 0,
        "end_h": 0,
        "end_m": 0,
      },
      ...inData,
    };

    if (opt_obj["info_arr"].length == 0) {
      alert("학생 선택이 필요합니다.");
      return false;
    }
    if (opt_obj["desk_info"] == null) {
      alert("책상정보가 없습니다.");
      return false;
    }
    if (!confirm("등록 하시겠습니까?")) {
      return false;
    }
    let u_info = opt_obj["info_arr"][0];
    let target_time_sec =
      strFunc.timeToSeconds(opt_obj["select_time"]["end_h"], opt_obj["select_time"]["end_m"], 0)
      - strFunc.timeToSeconds(opt_obj["select_time"]["start_h"], opt_obj["select_time"]["start_m"], 0);

    let desk_u_row = {
      "a_ymd": DateFunc.get_date_format(new Date(opt_obj["now_ymdhis"]), "Ymd"),
      "a_seq": "",
      "a_room_seq": opt_obj["desk_info"]["a_room_seq"],
      "a_x": opt_obj["desk_info"]["a_x"],
      "a_y": opt_obj["desk_info"]["a_y"],
      "a_date": DateFunc.get_date_format(new Date(opt_obj["now_ymdhis"]), "Y-m-d"),
      "a_user_seq": u_info["a_seq"],
      "a_user_name": u_info["a_user_name"],
      "a_user_grade": u_info["a_user_grade"],
      "a_start_h": opt_obj["select_time"]["start_h"],
      "a_start_m": opt_obj["select_time"]["start_m"],
      "a_end_h": opt_obj["select_time"]["end_h"],
      "a_end_m": opt_obj["select_time"]["end_m"],
      "a_target_time_sec": target_time_sec,
      "a_writer_seq": user.user_seq,
      "a_writer": user.user_name,
    };

    let desku_w_form_data = {
      "data_arr": [desk_u_row],
      "is_default_val": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/desku/add_arr', desku_w_form_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });

  };

  return (
    <div>
      <div>
        <SearchArea list={list} listOpt={listOpt} now_ymdhis={now_ymdhis}></SearchArea>
        {myProps.is_show_search_room &&
          <SearchRoomArea list={list} s_room_seq={listOpt.s_room_seq} search_room_list={search_room_list}></SearchRoomArea>
        }
        <RoomListArea ref={RoomListAreaRef}
          room_info_arr={infoArr}
          now_ymdhis={now_ymdhis}
          open_find_stu_popup={open_find_stu_popup}
          open_view_desk_popup={open_view_desk_popup}
          is_detal_popup={myProps.is_detal_popup}
        ></RoomListArea>
        {myProps.is_show_paging &&
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
        }
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} >
          {popupData.sort === "find_stu" &&
            <FindStudentPopup
              room_info={popupData.room_info}
              desk_info={popupData.desk_info}
              now_ymdhis={popupData.now_ymdhis}
              now_lecture_data={popupData.now_lecture_data}
              callBackData={callBackDataFindStu}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindStudentPopup>
          }
          {popupData.sort == "view_desk" &&
            <ViewDeskPopup
              room_info={popupData.room_info}
              desk_info={popupData.desk_info}
              desku_info={popupData.desku_info}
              now_ymdhis={popupData.now_ymdhis}
              now_lecture_data={popupData.now_lecture_data}
              callBackData={() => { list({ "now_page": "1" }) }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></ViewDeskPopup>
          }
        </LayerPopup>
      }
    </div>
  );
}

export default RoomListWrap;