import DateFunc from '@/lib/lyg/date_func';
import strFunc from '@/lib/lyg/string';
import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import CommentPartArea from "@/pcomponents/common/content/comment/part_area";
import ImgFileWriteList from "@/pages/comp/basic/file/frame/img/write_list";
import { useRef, useEffect } from 'react';

function TdContentArea(props: any) {
  let myProps = {
    name: "",
    week_info: {},
    week_idx: 0,
    week_idx_row_num: 0,
    on_change_week_info: (inData: any) => { },
    is_view_mode: false,
    openFindPrescribePopup: (inData: any) => { },
    openFindCounselorePopup: (inData: any) => { },
    openWriteCounselPopup: (inData: any) => { },
    comment_list: [],
    refresh_comment: (inData: any) => { },
    ...props
  };
  let name = myProps.name;
  let week_info = myProps.week_info;
  let is_view_mode = myProps.is_view_mode;

  let row_pri_val = week_info["a_ymd"] + "," + week_info["a_seq"];
  let is_update = false;
  let comment_init_data = {
    writer_seq: week_info.a_writer_seq,
    writer_name: week_info.a_writer_name,
    par_id: "month_prescribe",
    par_seq: row_pri_val,
    par_locate: "",
    par_locate_name: "",
    s_start_date: DateFunc.get_date_format(new Date(week_info["a_reserve_date"]), "Y-01-01"),
    s_end_date: DateFunc.get_date_format(new Date(), "Y-12-31"),
  };
  if (!strFunc.is_empty(week_info["a_seq"])) {
    is_update = true;
    comment_init_data["s_start_date"] = week_info["a_reserve_date"];
  }

  const ImgFileWriteListRef = useRef<any>(null);
  let img_file_init_list_opt = {
    "s_start_date": DateFunc.get_date_format(new Date(week_info["a_reserve_date"]), "Y-01-01"),
    "s_par_id": "month_prescribe",
    "s_par_seq": row_pri_val,
    "s_sort1": "a_lack",
    "s_sort2": "",
    "s_sort3": "",
  };
  useEffect(() => {
    if (ImgFileWriteListRef.current) {
      ImgFileWriteListRef.current.set_init_data({
        listOpt: img_file_init_list_opt,
        info_arr: week_info.file_arr?week_info.file_arr:[],
      });
    }
  }, [row_pri_val]);

  return (
    <div>
      {name == "a_progress_reason" &&
        <div>
          <div className="text-left pl-2" style={{ fontWeight: "bold" }}>1. 진도전략</div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView text={week_info.a_progress_reason}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_progress_reason"}
                value={week_info.a_progress_reason}
                class_name={"row-input"}
                height={32}
                style={{ "width": "100%" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_progress_reason",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                    week_idx_row_num: myProps.week_idx_row_num,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
        </div>
      }
      {name == "a_progress" &&
        <div>
          <div className="text-left pl-2" style={{ fontWeight: "bold" }}>2. 현재 진도</div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView text={week_info.a_progress}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_progress"}
                value={week_info.a_progress}
                class_name={"row-input"}
                height={32}
                style={{ "width": "100%" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_progress",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                    week_idx_row_num: myProps.week_idx_row_num,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
        </div>
      }
      {name == "a_grow" &&
        <div>
          <div className="text-left pl-2" style={{ fontWeight: "bold" }}>
            3. 성장한 부분
            {is_view_mode == false &&
              <button className="btn-s btn-dark ml-1" onClick={() => {
                myProps.openFindPrescribePopup({
                  row_num: myProps.week_idx,
                });
              }}>찾기</button>
            }
          </div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView text={week_info.a_grow}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_grow"}
                value={week_info.a_grow}
                class_name={"row-input"}
                height={32}
                style={{ "width": "100%" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_grow",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                    week_idx_row_num: myProps.week_idx_row_num,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
        </div>
      }
      {name == "a_lack" &&
        <div>
          <div className="text-left pl-2" style={{ fontWeight: "bold" }}>4. 발견</div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView text={week_info.a_lack}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_lack"}
                value={week_info.a_lack}
                class_name={"row-input"}
                height={32}
                style={{ "width": "100%" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_lack",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                    week_idx_row_num: myProps.week_idx_row_num,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
          {is_update &&
            <div>
              <ImgFileWriteList
                ref={ImgFileWriteListRef}
                is_view={false}
                listOpt={img_file_init_list_opt}
                is_save_btn={true}
                is_select_save={true}
                info_arr={week_info.file_arr?week_info.file_arr:[]}
                is_init_list={true}
              ></ImgFileWriteList>
            </div>
          }
          <div>
            {is_update &&
              <CommentPartArea
                info_arr={myProps.comment_list}
                list={myProps.refresh_comment}
                is_show_toggle={false}
                is_show_detail={true}
                is_send_write_push={true}
                title_str={"질문"}
                wrap_style={{ width: "100%" }}
                init_data={{
                  ...comment_init_data,
                  par_locate: "a_lack",
                  par_locate_name: "질문",
                }}
              ></CommentPartArea>
            }
          </div>
        </div>
      }
      {name == "a_content" &&
        <div>
          <div className="text-left pl-2" style={{ color: "#fff", background: "#2d8372", position: "relative" }}>
            5. 학습 처방
            <span style={{ position: "absolute", right: 5 }} title={week_info.a_is_success == "1" ? week_info.a_success_date : ""} >
              <label>
                <input type="checkbox" name="a_is_success" value="1"
                  className="ml-2 mr-1"
                  checked={week_info.a_is_success == "1"}
                  onChange={(e: any) => {
                    let change_val = "";
                    if (e.target.checked) {
                      change_val = "1";
                    }
                    if (is_view_mode == false) {
                      myProps.on_change_week_info({
                        name: "a_is_success",
                        value: change_val,
                        week_idx: myProps.week_idx,
                        week_idx_row_num: myProps.week_idx_row_num,
                      });
                    }
                  }}
                />
                완료
              </label>
              <label>
                <input type="checkbox" name="a_is_request_counsel" value="1"
                  className="ml-2 mr-1"
                  checked={week_info.a_is_request_counsel == "1"}
                  onChange={(e: any) => {
                    let change_val = "";
                    if (e.target.checked) {
                      change_val = "1";
                    }
                    if (is_view_mode == false) {
                      myProps.on_change_week_info({
                        name: "a_is_request_counsel",
                        value: change_val,
                        week_idx: myProps.week_idx,
                        week_idx_row_num: myProps.week_idx_row_num,
                      });
                    }
                  }}
                />
                상담요청
              </label>
              <span className="ml-1">
                {week_info.a_request_counselor &&
                  <span>
                    ({week_info.a_request_counselor})
                  </span>
                }
                {(week_info.a_request_counselor && is_view_mode == false) &&
                  <button className="btn-s btn-red ml-1" onClick={() => {
                    myProps.on_change_week_info({
                      name: "a_request_counselor_seq",
                      value: "",
                      week_idx: myProps.week_idx,
                      week_idx_row_num: myProps.week_idx_row_num,
                    });
                  }}>x</button>
                }
              </span>
              {is_view_mode == false &&
                <span>
                  <button className="btn-s btn-dark ml-1" onClick={() => {
                    myProps.openFindCounselorePopup({
                      row_num: myProps.week_idx,
                    });
                  }}>찾기</button>
                </span>
              }
            </span>
          </div>
          <div className="text-left mt-1" style={{ minHeight: 20 }}>
            {is_view_mode ?
              <MultyLineTextView
                text={week_info.a_content}
                style={{ color: "blue" }}></MultyLineTextView>
              :
              <TextAreaComponent
                name={"a_content"}
                value={week_info.a_content}
                class_name={"row-input"}
                height={32}
                style={{ color: "blue" }}
                onChange={(e: any) => {
                  myProps.on_change_week_info({
                    name: "a_content",
                    value: e.target.value,
                    week_idx: myProps.week_idx,
                    week_idx_row_num: myProps.week_idx_row_num,
                  });
                }}
                placeholder={"내용입력"}
              ></TextAreaComponent>
            }
          </div>
          {week_info.a_is_success == "1" &&
            <div>
              <div className="text-left" style={{ fontWeight: "bold", position: "relative" }}>
                <label>
                  <input type="checkbox" name="a_is_success_check" value="1"
                    className="ml-1 mr-1"
                    checked={week_info.a_is_success_check == "1"}
                    onChange={(e: any) => {
                      let change_val = "";
                      if (e.target.checked) {
                        change_val = "1";
                      }
                      if (is_view_mode == false) {
                        myProps.on_change_week_info({
                          name: "a_is_success_check",
                          value: change_val,
                          week_idx: myProps.week_idx,
                          week_idx_row_num: myProps.week_idx_row_num,
                        });
                      }
                    }}
                  />
                  완료확인
                </label>
                {week_info.a_is_success_check &&
                  <span className="ml-2" style={{ color: "#aaa" }}>{week_info.a_success_check_date}</span>
                }
                {week_info.a_is_request_counsel == "1" &&
                  <span style={{ position: "absolute", right: 0 }}>
                    <button className="btn-s btn-sky"
                      onClick={() => {
                        myProps.openWriteCounselPopup({
                          row_pri_val: row_pri_val,
                          row_data: week_info,
                        });
                      }}
                      style={{ background: strFunc.is_empty(week_info.a_request_counsel_seq) ? "gray" : "" }}>
                      상담{strFunc.is_empty(week_info.a_request_counsel_seq) ? "등록" : "수정"}
                    </button>
                  </span>
                }
              </div>
              <div className="text-left mt-1" style={{ minHeight: 20 }}>
                {is_view_mode ?
                  <MultyLineTextView text={week_info.a_success_check_msg}></MultyLineTextView>
                  :
                  <TextAreaComponent
                    name={"a_success_check_msg"}
                    value={week_info.a_success_check_msg}
                    class_name={"row-input"}
                    height={32}
                    style={{ "width": "100%" }}
                    onChange={(e: any) => {
                      myProps.on_change_week_info({
                        name: "a_success_check_msg",
                        value: e.target.value,
                        week_idx: myProps.week_idx,
                        week_idx_row_num: myProps.week_idx_row_num,
                      });
                    }}
                    placeholder={"내용입력"}
                  ></TextAreaComponent>
                }
              </div>
            </div>
          }

          <div>
            {is_update &&
              <CommentPartArea
                info_arr={myProps.comment_list}
                list={myProps.refresh_comment}
                is_show_toggle={false}
                is_show_detail={true}
                is_send_write_push={true}
                title_str={"질문"}
                wrap_style={{ width: "100%" }}
                init_data={{
                  ...comment_init_data,
                  par_locate: "a_content",
                  par_locate_name: "질문",
                }}
              ></CommentPartArea>
            }
          </div>
        </div>
      }
    </div>
  );
};
export default TdContentArea;