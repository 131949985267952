import TodoStateFunc from "@/pcomponents/common/content/todo/state/func/state_func";
function TodoHomeWorkTd(props: any) {
  let myProps = {
    info: {},
    select_date: "",
    pre_date_str: "",
    openTodoDaySort: (inData: any) => { },
    sort: "study",//pre_homework
    ...props
  };
  let info = myProps.info;
  let select_date = myProps.select_date;
  let pre_date_str = myProps.pre_date_str;
  let todo_study_file_cnt = TodoStateFunc.get_file_cnt_by_user_info({
    info: info,
    date_sort: "now",//pre,now
    select_date: select_date,
    pre_date_str: pre_date_str,
  });
  let home_work_file_cnt = TodoStateFunc.get_file_cnt_by_user_info({
    info: info,
    date_sort: "pre",//pre,now
    select_date: select_date,
    pre_date_str: pre_date_str,
  });

  let todo_per_average = 0;
  let per_average = 0;
  if (info.td_sort_data_json && info.td_sort_data_json["study"]) {
    todo_per_average = info.td_sort_data_json["study"]["percent"];
  }
  if (info.td_sort_data_json && info.td_sort_data_json["pre_homework"]) {
    per_average = info.td_sort_data_json["pre_homework"]["percent"];
  }
  let study_color_json = TodoStateFunc.get_color_of_percent(todo_per_average);
  let color_json = TodoStateFunc.get_color_of_percent(per_average);

  return (
    <td>
      {myProps.sort == "study" &&
        <div className="text-center" style={{ color: study_color_json.per, cursor: "pointer" }}
          onClick={() => {
            myProps.openTodoDaySort({
              select_stu_name: info["a_user_name"],
              select_stu_seq: info["a_seq"],
              select_date: select_date
            });
          }}>
          <span style={{ fontSize: "12px" }}>
            {todo_per_average}%
          </span>
          {todo_study_file_cnt > 0 &&
            <span className="ml-1" style={{ fontSize: "12px" }}>
              ({todo_study_file_cnt})
            </span>
          }
        </div>
      }
      {myProps.sort == "pre_homework" &&
        <div className="text-center" style={{ color: color_json.per, cursor: "pointer" }}
          onClick={() => {
            myProps.openTodoDaySort({
              select_stu_name: info["a_user_name"],
              select_stu_seq: info["a_seq"],
              select_date: pre_date_str
            });
          }}>
          <span style={{ fontSize: "12px" }}>
            {per_average}%
          </span>
          {home_work_file_cnt > 0 &&
            <span className="ml-1" style={{ fontSize: "12px" }}>
              ({home_work_file_cnt})
            </span>
          }
        </div>
      }
    </td>
  );
};
export default TodoHomeWorkTd;