import CompLayout from "@/pcomponents/comp/layout/layout";
import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import SearchArea from "./area/search";
import strFunc from "@/lib/lyg/string";
import DateFunc from '@/lib/lyg/date_func';
import LectureTimeTableArea from "./area/lecture_time_table";
import { useReactToPrint } from 'react-to-print';
import go_down_excel from "./area/excel/down_time_table";

function LectureListOneDay2() {
  let today_date_json = DateFunc.get_date_json(new Date());
  const [pageData, setPageData] = useState({
    listOpt: {
      "order_id": "a_order_num",
      "s_lecture_seq": "",
      "s_state": "ing",
      "s_teacher_like": "",
      "s_teacher_seq": "",
      "s_room_seq": "",
      "s_name_like": "",
      "s_day_num": [today_date_json["day"]],//today_date_json["day"]
      "s_addon_time": "1",
      "s_addon_user": "1",
      "s_addon_user_time": "1",
      "s_addon_user_s_state": "ing",
      "s_addon_time_union": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    }
  });
  const [infoArr, setInfoArr] = useState([]);
  const [teacher_arr, set_teacher_arr] = useState([]);
  const [room_arr, set_room_arr] = useState([]);
  const [is_teacher_nickname, set_is_teacher_nickname] = useState(true);
  const [stu_grade_sort, set_stu_grade_sort] = useState(
    strFunc.get_storage("time_table_stu_grade_sort", "a_stu_grade2")
  );//a_stu_grade2,a_stu_grade,공백
  const [is_print_land, set_is_print_land] = useState(
    strFunc.get_storage("time_table_one_day_is_print_land", "1") == "1" ? true : false
  );

  const time_table_wrap_ref = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => time_table_wrap_ref.current,
  });

  useEffect(() => {
    list({});
    get_teacher_by_ajax();
    get_room_by_ajax();
  }, []);

  const list = (inOptObj: any) => {
    if (inOptObj != undefined) {
      let tmpPageData: any = pageData;
      for (let key in tmpPageData.listOpt) {
        if (inOptObj[key] != undefined) {
          tmpPageData.listOpt[key] = inOptObj[key];
        }
      }
      setPageData({ ...tmpPageData });
    }
    let list_form_json_data = pageData.listOpt;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/lecture/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const get_teacher_by_ajax = () => {
    let list_form_json_data = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };
  const get_room_by_ajax = () => {
    let list_form_json_data = {
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/room/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_room_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const onClickPrint = () => {
    if (time_table_wrap_ref.current) {
      time_table_wrap_ref.current.style.transform = "scale(1)";
      handlePrint();
    }
  };

  const go_excel_down_action = () => {
    if (!confirm("엑셀로 받겠습니까?")) {
      return false;
    }
    go_down_excel({
      lecture_arr: infoArr,
      room_arr: room_arr,
      teacher_arr: teacher_arr,
      is_teacher_nickname: is_teacher_nickname,
      stu_grade_sort: stu_grade_sort
    });
  };

  return (
    <CompLayout isConTitle={false}>
      <SearchArea
        listOpt={pageData.listOpt}
        list={list}
        teacher_arr={teacher_arr}
        room_arr={room_arr}
        is_teacher_nickname={is_teacher_nickname}
        set_is_teacher_nickname={set_is_teacher_nickname}
        stu_grade_sort={stu_grade_sort}
        set_stu_grade_sort={(inData: any) => {
          set_stu_grade_sort(inData);
          strFunc.set_storage("time_table_stu_grade_sort", inData);
        }}
      ></SearchArea>
      <div className="my-1 text-center">
        <select value={is_print_land ? "1" : "2"}
          onChange={(e: any) => {
            if (e.target.value == "1") {
              set_is_print_land(true);
            } else {
              set_is_print_land(false);
            }
            strFunc.set_storage("time_table_one_day_is_print_land", e.target.value);
          }}
          style={{ border: "1px solid #ddd" }}>
          <option value="1">가로</option>
          <option value="2">세로</option>
        </select>
        <button className="btn btn-sky ml-1" onClick={() => { onClickPrint(); }}>프린트</button>
        <button className="btn btn-sky ml-2" onClick={() => { go_excel_down_action(); }}>엑셀다운</button>
        <button className="btn btn-gray ml-2" onClick={() => { window.open("/comp/edu/lecture/list_one_day2"); }}>기존 시간표</button>
      </div>
      <div ref={time_table_wrap_ref} style={{padding:5,margin:2}}>
        <style type="text/css" media="print">
          {is_print_land ? " @page { size: landscape; }@media print {html,body {width: 290mm;max-height:201mm; }} " : ""}
        </style>
        <LectureTimeTableArea
          lecture_arr={infoArr}
          teacher_arr={teacher_arr}
          room_arr={room_arr}
          is_teacher_nickname={is_teacher_nickname}
          stu_grade_sort={stu_grade_sort}
        ></LectureTimeTableArea>
      </div>
    </CompLayout>
  );
}

export default LectureListOneDay2;