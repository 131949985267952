import { useState, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from '@/lib/lyg/string';
import { useSelector, useDispatch } from 'react-redux';
import SearchLectureSelect from "@/pcomponents/common/content/basic/student/search/search_lecture_select";

function SearchArea(props: any) {
  let myProps = {
    listOpt: {},
    list: (inData: any) => { },
    ...props
  };
  let listOpt = myProps.listOpt;

  let user = useSelector((state: any) => state.user);
  const [teacher_arr, set_teacher_arr] = useState([]);
  useEffect(() => {
    get_teacher_by_ajax();
  }, []);

  const get_teacher_by_ajax = () => {
    let list_form_json_data = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
        } else {

        }
      });
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      return false;
    }
    myProps.list({ now_page: 1, [name]: value });
  };
  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    myProps.list({ now_page: 1, [key]: date_str });
  };
  const goDateDayChange = (sort: "pre" | "next") => {
    //next,pre
    let selected_date = listOpt.s_start_date;
    if (selected_date == "") {
      selected_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    let change_date_obj = new Date(selected_date);
    if (sort == "pre") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', -1);
    } else if (sort == "next") {
      change_date_obj = DateFunc.get_change_date(new Date(selected_date), 'day', +1);
    }

    let start_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    let end_date = DateFunc.get_date_format(change_date_obj, "Y-m-d");
    myProps.list({
      now_page: "1",
      s_start_date: start_date,
      s_end_date: end_date,
    });
  };

  const change_start_end_date_by_sort = (sort: "today" | "this_month" | "pre_month") => {
    let now_month_init_date = DateFunc.get_date_format(new Date(), "Y-m-01");
    let s_start_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    let s_end_date = DateFunc.get_date_format(new Date(), "Y-m-d");
    if (sort == "this_month") {
      s_start_date = DateFunc.get_date_format(new Date(), "Y-m-01");
      s_end_date = DateFunc.get_date_format(new Date(), "Y-m-t");
    } else if (sort == "pre_month") {
      s_start_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-01");
      s_end_date = DateFunc.get_date_format(new Date(DateFunc.get_change_date(new Date(now_month_init_date), 'month', -1)), "Y-m-t");
    }
    myProps.list({
      now_page: 1,
      s_start_date: s_start_date,
      s_end_date: s_end_date,
    });
  };

  return (
    <div>
      <div>
        기간:
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_start_date != "" ? new Date(listOpt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={listOpt.s_end_date != "" ? new Date(listOpt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("pre"); }}>◀</button>
        <button className="btn-s2 btn-dark ml-1" onClick={() => { goDateDayChange("next"); }}>▶</button>
        <button className="btn-s btn-dark ml-1"
          style={{ "background": "#0097BA" }}
          onClick={() => { change_start_end_date_by_sort("today"); }} >오늘</button>
        <button className="btn-s btn-dark ml-1"
          style={{ "background": "#0097BA" }}
          onClick={() => { change_start_end_date_by_sort("this_month"); }} >이번달</button>
        <button className="btn-s btn-dark ml-1"
          style={{ "background": "#0097BA" }}
          onClick={() => { change_start_end_date_by_sort("pre_month"); }} >지난달</button>

        <span className="ml-2">
          학생
          <input type="text" className="search-input ml-1"
            onChange={handleInputChange} name="s_stu_name_like" value={listOpt.s_stu_name_like}
            autoComplete="off"
            style={{ width: 80 }} />
        </span>
        <div className="ml-1" style={{display:"inline-block"}}>
          <SearchLectureSelect
            name="s_stu_lecture_seq"
            value={listOpt.s_stu_lecture_seq}
            on_change_input={(inData: any) => {
              let name = inData.name;
              let value = inData.value;
              myProps.list({ now_page: 1, [name]: value });
            }}
          ></SearchLectureSelect>
        </div>
        <div className="basic_search_item_book_mark"
          style={{ display: "inline-block", cursor: "pointer" }}
          onClick={() => {
            let change_s_stu_bookmark = listOpt.s_stu_bookmark;
            if (change_s_stu_bookmark == "1") {
              change_s_stu_bookmark = "";
            } else {
              change_s_stu_bookmark = "1";
            }
            strFunc.set_storage("s_stu_bookmark", change_s_stu_bookmark);
            myProps.list({ now_page: 1, "s_stu_bookmark": change_s_stu_bookmark });
          }}>
          {listOpt.s_stu_bookmark == "1" ? "★" : "☆"}
        </div>
        <span className="ml-2">
          선생님
          <select className="search-input ml-1" name="s_owner_mcomp_user_seq" value={listOpt.s_owner_mcomp_user_seq}
            onChange={handleInputChange} style={{ width: 100 }}>
            <option value="">전체</option>
            {teacher_arr.map((item: any, idx: number) => {
              return (
                <option key={idx} value={item["a_seq"]}>
                  {item["a_user_name"]}
                  {item["a_user_nickname"] &&
                    "(" + item["a_user_nickname"] + ")"
                  }
                </option>
              );
            })}
          </select>
          <button className="btn-s btn-dark ml-1"
            onClick={() => {
              myProps.list({ now_page: 1, s_owner_mcomp_user_seq: user.user_seq });
            }}>나</button>
        </span>
        <span className="ml-2">
          <input type="checkbox" checked={listOpt.s_is_success == "1" ? true : false} value="1"
            onChange={handleInputChange} name="s_is_success" />
          <select className="search-input ml-1" name="s_is_success" value={listOpt.s_is_success}
            onChange={handleInputChange}>
            <option value="">완료여부</option>
            <option value="1">완료</option>
            <option value="empty">미완료</option>
          </select>
        </span>
      </div>
    </div>
  );
};
export default SearchArea;