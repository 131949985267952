import strFunc from "@/lib/lyg/string";
import React from 'react';
import StuNameTd from "./area/stu/stu_name_td";
import AttendTd from "./area/stu/attend_td";
import TodoHomeWorkTd from "./area/stu/todo_home_work";
import DailyInputText from "./area/daily/input_text";
import MonthPrescribeStateTd from "./area/month_prescribe/state_td";
import DailyDataFunc from "@/pages/comp/edu/daily_report/card_list/func/daily_data_func";

function CardBoxArea(props: any) {
  let myProps = {
    row_num: 0,
    first_td_tag: "",
    lecture_info: null,
    info: {},
    set_info: (info: any) => { },
    xColumnArr: {},
    select_date: "",
    pre_date_str: "",
    list: (inData: any) => { },
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    Style: {},
    show_week_arr: [],
    lecture_time_str: "",
    select_stu_grade_arr: [],
    openAttendPopup: (inData: any) => { },
    deleteAttend: (inData: any) => { },
    open_write_td_data_popup: (inData: any) => { },
    openTodoDaySort: (inData: any) => { },
    open_stu_layer_popup: (inData: any) => { },
    open_find_study_title_popup: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let daily_report_arr = [];
  if (info.daily_report_arr) {
    daily_report_arr = info.daily_report_arr;
  }
  let xColumnArr = myProps.xColumnArr;

  let lecture_info = null;
  let lecture_seq = "";
  if (!strFunc.is_empty(myProps.lecture_info)) {
    lecture_info = myProps.lecture_info;
    lecture_seq = lecture_info["a_seq"];
  }

  let last_daily_info = {
    a_stu_seq: "",
    a_writer_seq: "",
    a_lecture_seq: "",
    a_textbook: "",
    a_subtextbook: "",
    a_homework: "",
  };
  if (info.last_daily_report_arr && info.last_daily_report_arr.length > 0 && lecture_info != null) {
    for (let i = 0; i < info.last_daily_report_arr.length; i++) {
      let row_daily_info = info.last_daily_report_arr[i];
      if (row_daily_info["a_stu_seq"] == info["a_seq"]
        && row_daily_info["a_writer_seq"] == lecture_info["a_main_teacher_seq"]
        && row_daily_info["a_lecture_seq"] == lecture_info["a_seq"]) {
        last_daily_info = row_daily_info;
      }
    }
  }
  let daily_info = DailyDataFunc.get_daily_info_of_date({
    date_str: myProps.select_date,
    pre_date_str: myProps.pre_date_str,
    last_daily_info: last_daily_info,
    lecture_time_str: myProps.lecture_time_str,
    info: info,
    lecture_info: lecture_info,
  });

  let is_checked_row=false;
  if(strFunc.str_in_array(info["a_seq"],myProps.check_row_val_arr)!=-1){
    is_checked_row=true;
  }

  const on_checkbox_change = (e: any) => {
    if(e.target.checked){
      let change_check_row_val_arr=myProps.check_row_val_arr;
      change_check_row_val_arr.push(info["a_seq"]);
      myProps.set_check_row_val_arr(change_check_row_val_arr);
    }else{
      let change_check_row_val_arr=[];
      for(let i=0;i<myProps.check_row_val_arr.length;i++){
        if(myProps.check_row_val_arr[i]!=info["a_seq"]){
          change_check_row_val_arr.push(myProps.check_row_val_arr[i]);
        }
      }
      myProps.set_check_row_val_arr(change_check_row_val_arr);
    }
  };

  const on_change_daily_input=(inData:any)=>{
    let opt_obj={
      name:"",
      value:"",
      change_row_data:{},
      ...inData
    };
    let value=opt_obj.value;
    let name=opt_obj.name;
    let daily_row_num=-1;
    if (info.daily_report_arr && info.daily_report_arr.length > 0) {
      for (let i = 0; i < info.daily_report_arr.length; i++) {
        let row_daily_info = info.daily_report_arr[i];
        if(row_daily_info["a_ymd"]==daily_info["a_ymd"]
            &&row_daily_info["a_seq"]==daily_info["a_seq"]){
          daily_row_num=i;
        }
      }
    }

    daily_info[name]=value;
    for(let key in opt_obj.change_row_data){
      daily_info[key]=opt_obj.change_row_data[key];
    }

    if(daily_row_num!=-1){
      info.daily_report_arr[daily_row_num]=daily_info;
    }
    if(daily_row_num==-1){
      if(info.daily_report_arr==undefined){
        info.daily_report_arr=[];
      }
      info.daily_report_arr.push(daily_info);
    }
    myProps.set_info(info);
  };

  let get_td_tag_by_data = (inData: any) => {
    let opt_obj = {
      key: "",
      td_render: "",
      ...inData
    };
    let key = opt_obj.key;
    let td_render = opt_obj.td_render;

    if (key == "row_view_checkbox") {
      td_render = (
        <td>
          <input
            type="checkbox"
            onChange={on_checkbox_change}
            value={""}
            checked={is_checked_row}
          />
        </td>
      );
    } else if (key == "row_view_stu_name") {
      td_render = (
        <StuNameTd
          info={info}
          daily_info={daily_info}
          select_stu_grade_arr={myProps.select_stu_grade_arr}
          open_stu_layer_popup={myProps.open_stu_layer_popup}
        ></StuNameTd>
      );
    } else if (key == "row_view_month_prescribe") {
      td_render = (
        <MonthPrescribeStateTd
          info={info}
          open_stu_layer_popup={myProps.open_stu_layer_popup}
        ></MonthPrescribeStateTd>
      );
    } else if (key == "row_view_attend") {
      td_render = (
        <AttendTd
          info={info}
          openAttendPopup={myProps.openAttendPopup}
          deleteAttend={myProps.deleteAttend}
        ></AttendTd>
      );
    }else if (key == "row_view_todo_study") {
      td_render = (
        <TodoHomeWorkTd
          info={info}
          select_date={myProps.select_date}
          pre_date_str={myProps.pre_date_str}
          openTodoDaySort={myProps.openTodoDaySort}
          sort="study"
        ></TodoHomeWorkTd>
      );
    }else if (key == "row_view_todo_homework") {
      td_render = (
        <TodoHomeWorkTd
          info={info}
          select_date={myProps.select_date}
          pre_date_str={myProps.pre_date_str}
          openTodoDaySort={myProps.openTodoDaySort}
          sort="pre_homework"
        ></TodoHomeWorkTd>
      );
    }else if (key == "row_view_homework") {
      td_render = (
        <DailyInputText
          daily_info={daily_info}
          name="a_homework"
          onChange={on_change_daily_input}
        ></DailyInputText>
      );
    }else if (key == "row_view_textbook") {
      td_render = (
        <DailyInputText
          daily_info={daily_info}
          name="a_textbook"
          onChange={on_change_daily_input}
          onClick={()=>{
            myProps.open_find_study_title_popup({
              row_num: info["a_seq"],
              key_str: "a_textbook",
              par_info:daily_info
            });
          }}
        ></DailyInputText>
      );
    }else if (key == "row_view_textbook_study") {
      td_render = (
        <DailyInputText
          daily_info={daily_info}
          name="a_textbook_study"
          onChange={on_change_daily_input}
          info={info}
        ></DailyInputText>
      );
    }else if (key == "row_view_textbook_study_det") {
      td_render = (
        <DailyInputText
          daily_info={daily_info}
          name="a_textbook_study_det"
          onChange={on_change_daily_input}
          info={info}
        ></DailyInputText>
      );
    }else if (key == "row_view_subtextbook") {
      td_render = (
        <DailyInputText
          daily_info={daily_info}
          name="a_subtextbook"
          onChange={on_change_daily_input}
          onClick={()=>{
            myProps.open_find_study_title_popup({
              row_num: info["a_seq"],
              key_str: "a_subtextbook",
              par_info:daily_info
            });
          }}
        ></DailyInputText>
      );
    }else if (key == "row_view_memo") {
      td_render = (
        <DailyInputText
          daily_info={daily_info}
          name="a_memo"
          onChange={on_change_daily_input}
        ></DailyInputText>
      );
    } else {
      td_render = (
        <td>
          {td_render}
        </td>
      );
    }

    return td_render;
  };

  let get_td_tags = () => {
    let td_tags = xColumnArr.x_column_list_arr.map((item: any, idx: number) => {
      if (item["key"] == "row_view_time") { return false; }
      if (item["is_show"] != "1") { return false; }

      let td_render: any = get_td_tag_by_data({
        key: item["key"],
      });

      return (
        <React.Fragment key={idx}>
          {td_render}
        </React.Fragment>
      );
    });
    return td_tags;
  };

  return (
    <>
      <tr className={Style.card_box_first_tr}>
        {myProps.row_num == 0 && myProps.first_td_tag}
        {get_td_tags()}
      </tr>
    </>
  );
};
export default CardBoxArea;