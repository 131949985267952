export const default_info={
  "a_ymd":"",
  "a_seq":"",
  "a_reserve_date":"",
  "a_is_write":"",
  "a_write_date":"",
  "a_stu_seq":"",
  "a_stu_name":"",
  "a_writer_seq":"",
  "a_writer_name":"",
  "a_subject_name":"",
  "a_title":"",
  "a_progress_reason":"",
  "a_progress":"",
  "a_grow":"",
  "a_lack":"",
  "a_content":"",
  "a_is_success":"",
  "a_success_date":"",
  "a_success_writer_seq":"",
  "a_success_writer_name":"",
  "a_is_success_check":"",
  "a_success_check_date":"",
  "a_success_check_msg":"",
  "a_is_request_counsel":"",
  "a_request_counsel_date":"",
  "a_request_counsel_msg":"",
  "a_request_counselor_seq":"",
  "a_request_counselor":"",
  "a_request_counsel_writer_seq":"",
  "a_request_counsel_writer":"",
  "a_request_counsel_seq":"",
};