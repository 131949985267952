import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import getXcolumnJson from "./xcolumn/list";
import ListComponent from '@/pcomponents/common/crud/list/list/list_component';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useSelector, useDispatch } from 'react-redux';
import XcolumnFunc from "@/pcomponents/common/xcolumn/xcolumn/xcolumn_func";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/comp/basic/student/popup/find_multy";
import TStuTodoWriteMultyPopup from "@/pages/comp/tea/tstu_todo/popup/write_multy";
import TStuTodoBaseFunc from "@/pcomponents/common/content/tea/tstu_todo/func/base_func";
import SearchArea from "./area/search";
import ManageArea from "./area/manage";

function TStuListMultyArea(props: any) {
  let myProps = {
    ...props
  };

  let user = useSelector((state: any) => state.user);
  const xColumnArr = getXcolumnJson();
  let select_stu_grade_opt_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
  const ListComponentRef = useRef<any>();
  let pre_month_y_m_d = DateFunc.get_date_format(
    DateFunc.get_change_date(new Date(DateFunc.get_date_format(new Date(), "Y-m-01")), 'month', -1)
    , "Y-m-01");
  const [listOpt, setListOpt] = useState({
    ...xColumnArr.list_opt,
    s_addon_comp_user: "1",
    s_start_date:pre_month_y_m_d,
    s_end_date:DateFunc.get_date_format(new Date(), "Y-m-d"),
    s_subject: "",
    s_title: "",
    s_stu_name_like: "",
    s_owner_mcomp_user_seq: "",
    s_is_success: "",
    s_stu_lecture_seq: user.user_grade == "master" ? "" : strFunc.get_storage_array("s_stu_lecture_seq", "", ","),
    s_stu_bookmark: strFunc.get_storage("s_stu_bookmark"),
  });
  const [infoArr, setInfoArr] = useState<any>([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [teacher_arr, set_teacher_arr] = useState([]);
  const [iam_info, set_iam_info] = useState({});
  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find",//view
    row_num: 0,
    key_str: "",
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    list({});
    get_teacher_by_ajax();
  }, [user]);

  const list = (inData: any) => {
    let form_json_data: any = {
      ...listOpt,
      ...inData
    };
    setListOpt(form_json_data);
    if (strFunc.is_empty(user.comp_seq)) {
      return false;
    }
    form_json_data["s_mcomp_seq"] = user.comp_seq;
    form_json_data["s_public_mcomp_user_seq"] = user.user_seq;
    form_json_data["s_public_user_grade"] = user.user_grade;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          ListComponentRef.current.setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
          setTimeout(() => {
            set_idx_num_at_info_arr();
          }, 100);
        } else {

        }
      });
  };

  const get_teacher_by_ajax = () => {
    let list_form_json_data = {
      "s_grade": ["master", "teacher"],
      "order_id": "a_user_name",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', list_form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_teacher_arr(response.data["data"]["info_arr"]);
          for (let i = 0; i < response.data["data"]["info_arr"].length; i++) {
            if (response.data["data"]["info_arr"][i]["a_seq"] == user.user_seq) {
              set_iam_info(response.data["data"]["info_arr"][i]);
            }
          }
        } else {

        }
      });
  };

  const set_idx_num_at_info_arr = () => {
    let all_rows = ListComponentRef.current.getRows();
    let change_rows = [];
    for (let i = 0; i < all_rows.length; i++) {
      let row = all_rows[i];
      row["idx_num"] = i + 1;
      change_rows.push(row);
    }
    ListComponentRef.current.setInfoArr(change_rows);
    setInfoArr(change_rows);
  };

  const goWriteArr = (e: any) => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    if (!confirm("선택 수정 하시겠습니까?")) {
      return false;
    }
    let form_json_data={
      data_arr: selected_row,
      is_send_push:"1",
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/write',
      form_json_data, get_axios_data()).then((response) => {
      if (response.data["result"] === "true") {
        alert(response.data["msg"]);
        list({});
      } else {
        alert(response.data["msg"]);
      }
    });
  };

  const goDelete = (e: any) => {
    let selected_row = ListComponentRef.current.getSelectedRows();
    if (selected_row.length === 0) {
      alert("선택없음.");
      return false;
    }
    let no_saved_row_idx_arr = [];
    let del_select_rows = [];
    for (let i = 0; i < selected_row.length; i++) {
      let tmp_row = selected_row[i];
      if (strFunc.is_empty(tmp_row["a_seq"])) {
        no_saved_row_idx_arr.push(tmp_row["idx_num"]);
      } else {
        del_select_rows.push(tmp_row);
      }
    }
    if (no_saved_row_idx_arr.length > 0) {
      let all_rows = ListComponentRef.current.getRows();
      let remain_rows = [];
      for (let i = 0; i < all_rows.length; i++) {
        let tmp_row = all_rows[i];
        if (strFunc.str_in_array(tmp_row["idx_num"], no_saved_row_idx_arr) == -1) {
          remain_rows.push(all_rows[i]);
        }
      }
      ListComponentRef.current.setInfoArr(remain_rows);
      setTimeout(() => {
        set_idx_num_at_info_arr();
      }, 100);
    }
    if (del_select_rows.length === 0) {
      return false;
    }
    if (!confirm("삭제하시겠습니까?")) {
      return false;
    }
    let form_json_data = {
      data_arr: del_select_rows,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/tea/tstu_todo/delete', form_json_data,
      get_axios_data()).then((response) => {
        if (response.data["result"] === "true") {
          list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const goAddRow = (inData:any) => {
    let opt_obj={
      row_data:{},
      ...inData
    };
    let add_new_row: any = TStuTodoBaseFunc.get_default_row_data({
      user: user,
      iam_info: iam_info,
      row_data: opt_obj.row_data,
    });
    let all_rows = ListComponentRef.current.getRows();
    add_new_row["idx_num"] = all_rows.length + 1;
    let change_rows = [
      ...all_rows,
      ...[add_new_row]
    ];
    ListComponentRef.current.setInfoArr(change_rows);
    setInfoArr(change_rows);
  };

  const open_find_stu_popup = (inData: any) => {
    let opt_obj = {
      key_str: "a_stu_name",
      row_num: 0,
      ...inData
    };

    let pop_title = "학생찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "find",
      key_str: opt_obj.key_str,
      row_num: opt_obj.row_num,
      width: "800px",
      height: "80%",
    });
  };

  const callBackDataStuPopup = (inData: any) => {
    let opt_obj = {
      info_arr: [],
      key_str: "",
      row_num: 0,
      ...inData
    };
    let all_rows = ListComponentRef.current.getRows();
    if (opt_obj.info_arr.length == 0) {
      return false;
    };
    let row_num = opt_obj.row_num;
    let key_str = opt_obj.key_str;
    let stu_info = opt_obj.info_arr[0];
    if (all_rows[row_num]) {
      if (key_str == "a_stu_name") {
        all_rows[row_num]["a_stu_name"] = stu_info["a_user_name"];
        all_rows[row_num]["a_mcomp_stu_seq"] = stu_info["a_seq"];
        all_rows[row_num]["stu_info"] = stu_info;
      }
      ListComponentRef.current.setInfoArr(all_rows);
      setInfoArr(all_rows);
    }
  };

  const open_write_multy_popup = (inData: any) => {
    let opt_obj = {
      ...inData
    };

    let pop_title = "다량등록";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "write_multy",
      width: "1200px",
      height: "80%",
    });
  };

  const cellRenderer = useCallback((inOpt: any) => {
    let opObj = {
      "rowData": {},
      "idx": 0,
      "key": "",
      "value": "",
      ...inOpt
    };

    return opObj.value;
  }, []);

  const customGridCellRenderer = (params: any) => {
    /*
    {
      "rowData":rowData,
      "idx":idx,
      "key":key,
      "value":row_val,
      "input_tag":input_tag,
      "onChangeInput":onChangeInput,
    }
    */
    let key = params.key;
    if (key == "a_stu_name") {
      let stu_grade_str = "";
      if (params.rowData["stu_info"]) {
        stu_grade_str = params.rowData["stu_info"]["a_stu_grade"];
        for (let i = 0; i < select_stu_grade_opt_arr.length; i++) {
          if (select_stu_grade_opt_arr[i]["value"] == stu_grade_str) {
            stu_grade_str = select_stu_grade_opt_arr[i]["text"];
          }
        }
      }
      let stu_div_style: any = {};
      if (strFunc.is_empty(params.rowData["a_seq"])) {
        stu_div_style["background"] = "#ffa883";
        stu_div_style["lineHeight"] = "20px";
        stu_div_style["borderRadius"] = "5px";
      }
      params.input_tag = (
        <div style={stu_div_style}>
          {params.value} {stu_grade_str}
          <button className="btn-s btn-dark ml-1"
            onClick={() => {
              open_find_stu_popup({
                key_str: "a_stu_name",
                row_num: params.idx,
              });
            }}
          >찾기</button>
        </div>
      );
    } else if (key == "a_owner_mcomp_user_seq") {
      params.input_tag = (
        <span>
          {params.rowData["a_owner_name"]}
        </span>
      );
    } else if (key == "a_is_success") {
      let is_success_div_style: any = {};
      is_success_div_style["background"] = "#ffbea7";
      is_success_div_style["lineHeight"] = "20px";
      is_success_div_style["borderRadius"] = "5px";
      if (params.value == "1") {
        is_success_div_style["background"] = "#90fa39";
      }
      params.input_tag = (
        <label>
          <div style={is_success_div_style}>
            <input type="checkbox" checked={params.value == "1" ? true : false}
              value={"1"}
              style={{marginTop:5}}
              onChange={(e: any) => {
                if(params.rowData["a_is_success"]!="1"){
                  if(e.target.checked){
                    params.rowData["a_success_date"]=DateFunc.get_date_format(new Date(),"Y-m-d h:i:s");
                    params.rowData["a_success_mcomp_user_seq"]=user.user_seq;
                    params.rowData["a_success_main_user_seq"]="";
                    params.rowData["a_success_user_name"]=user.user_name;
                  }
                }
                params.onChangeInput({
                  "rowData": params.rowData,
                  "row_num": params.idx,
                  "key": key,
                  "value": e.target.checked ? "1" : "",
                  "pre_value": params.value,
                });
              }}
            />
          </div>
        </label>
      );
    }else if(key=="a_success_user_name"){
      if(params.rowData["a_is_success"]=="1"){
        params.input_tag=params.value;
        params.input_tag=(
          <span title={params.rowData.a_success_date}>
            {params.input_tag}
          </span>
        );
      }else{
        params.input_tag="";
      }
    }
    return params.input_tag;
  };

  return (
    <div>
      <div>
        <SearchArea
          listOpt={{ ...listOpt }}
          xColumnArr={{ ...xColumnArr }}
          list={list}
        ></SearchArea>
      </div>
      <div className="mt-1">
        <ManageArea
          goWriteArr={goWriteArr}
          goDelete={goDelete}
          goAddRow={goAddRow}
          open_write_multy_popup={open_write_multy_popup}
          ListComponentRef={ListComponentRef}
          infoArr={infoArr}
          setInfoArr={setInfoArr}
          teacher_arr={teacher_arr}
          xColumnArr={xColumnArr}
        ></ManageArea>
      </div>
      <div className="mt-1">
        <ListComponent
          ref={ListComponentRef}
          infoArr={infoArr}
          xColumnArr={{ ...xColumnArr }}
          gridOpt={{
            isGrid: true,
            is_add_idx_num: false,
            is_add_checkbox: true,
            is_auto_max_width: true,
            td_align: "left",//center,right
            table_align: "left",//center,right
            onGridReady: () => {
              //list({});
            },
            customCellRenderer: cellRenderer,
            customGridCellRenderer: customGridCellRenderer,
          }}
        ></ListComponent>
        <Paging now_page={listOpt.now_page}
          num_per_page={listOpt.num_per_page}
          total_rec={countInfo.tot}
          onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
          is_view_num_per_page={true}
          onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} >
          {popupData.sort === "find" &&
            <FindMultyStudentPopup
              callBackData={callBackDataStuPopup}
              key_str={popupData.key_str}
              row_num={popupData.row_num}
              listOpt={{

              }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindMultyStudentPopup>
          }
          {popupData.sort === "write_multy" &&
            <TStuTodoWriteMultyPopup
              callBackData={(inData:any)=>{list({});}}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></TStuTodoWriteMultyPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default TStuListMultyArea;